import { Link, TextInput } from "carbon-components-react"
import { hasCapabilitySupport } from "../../../app/Capabilities"
import Button from "../../../components/Button"
import { StockReqItemOpts } from "../../../components/list-selector"
import { TableInput } from "../../../components/table-input"
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { openPdf } from "../../../markup-template-engine"
import { getAccountRole } from "../../../session/SessionManager"
import { FormSection } from "../../../templates/form/form"
import { newListDetail } from "../../base/list-detail"
import { Cube32, Cube24, Checkmark16, Barcode16, Scan32, Close16 } from '@carbon/icons-react'
import { useMemo } from "react"
import useStore from "../../../hooks/useStore"
import Util, { big } from "../../../util/Util"
import { useState } from "react"
import { useEffect } from "react"
import Api from "../../../session/Api"
import { OBJECT_TYPE_INV_ADJUSTMENT, OBJECT_TYPE_STORE, OBJECT_TYPE_WAREHOUSE } from "../../../constants/ObjectTypes"
import UIUtil from "../../../util/UIUtil"
import StockFlowItemPage from "../../../pages/stock-flow/StockFlowItemPage"
import { AdvancedNotesView } from "../../../views/advanced-notes/advanced-notes-view"
import { DateRange2 } from "../../components/basic-filter/date-range"
import { MultiSelectListMode } from "../../components/basic-filter/multi-select"
import { TextBox } from "../../components/basic-filter/text-box"
import { useBarcodeScanner } from "../../../hooks/useBarcodeScanner"

export const InvAdjustmentList = newListDetail({
    title: "Stock Adjustment",
    icon: Cube32,
    mdIcon: Cube24,

    filter: Filter, newForm: Form, updateForm: Form,

    openDetailOnCreate: true,
    clearStateOnSave: true,
    skipInlinePadding: true,
    canDelete: true,

    defaultValues: {
        locationType: OBJECT_TYPE_STORE,
        items: [],
        // date: new Date().getTime(),
    },

    isAvailable: () => {
        if (hasCapabilitySupport("cargo")) {
            return false;
        }

        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    },

    renderOptions: (props) => <PendingOptions {...props} />,
})


function ItemListField({ store }) {
    const [items, setItems] = useStore(store, 'items');

    const cols = useMemo(() => [
        {
            key: "item", title: "Item", flex: 3, Component: ({ value, setMultiProp }) => {
                const setItem = item => {
                    // REPEATED in barcode scanner below
                    setMultiProp({
                        item,
                        itemId: item?.itemId ?? 0,
                        itemType: item?.itemType ?? 0,
                    })
                }
                return (
                    <div style={{ height: 40, width: '100%' }}>
                        <StockReqItemOpts value={value} setValue={setItem} />
                    </div>
                )
            }
        },
        {
            key: "item", title: "Barcode", flex: 2, Component: ({ value }) => {
                const barcodes = value?.barcodes ?? [];
                return <TextInput value={barcodes.join(", ")} />
            }
        },
        {
            key: "currentQty", title: "Current Qty", group: "Quantity Change", flex: 1, Component: ({ useEntry }) => {
                const [currentQty, setCurrentQty] = useState("")
                const [locationId] = useStore(store, "locationId");
                const [itemId] = useEntry("itemId");

                useEffect(() => {
                    if (!locationId || !itemId) {
                        setCurrentQty("");
                        return;
                    }

                    let cancelled = false;

                    setCurrentQty("Loading...");
                    Api.getProductCurrentQty(locationId, itemId, response => {
                        if (cancelled) {
                            return;
                        }

                        if (response.status === true) {
                            setCurrentQty(response.payload);
                        } else {
                            setCurrentQty("");
                        }
                    })

                    return () => {
                        cancelled = true;
                    }
                }, [locationId, itemId]);

                return <TextInput style={{ fontWeight: 'bold' }} value={currentQty} />
            }
        },
        {
            key: "counting", title: "Counting", flex: 1, Component: ({ value, setValue }) => {
                return <TextInput value={value} onChange={e => setValue(e.target.value)} />
            }
        },
        {
            key: "newQty", title: "New Qty", flex: 1, Component: ({ useEntry }) => {
                const [itemId] = useEntry("itemId");
                const [items] = useStore(store, "items");

                const itemTotalCount = useMemo(() => {
                    if (!itemId) {
                        return undefined;
                    }

                    if (!items) {
                        return 0;
                    }

                    return items
                        .filter(item => item.itemId == itemId)
                        .map(item => big(item.counting))
                        .reduce((t, c) => t.add(c), big(0))
                        .toNumber();
                }, [itemId, items]);

                return <TextInput style={{ fontWeight: 'bold' }} value={itemTotalCount ?? ''} />
            }
        },
        {
            key: "memo", title: "Memo", flex: 3, group: "Adjustment Info", Component: ({ value, setValue }) => {
                return <TextInput value={value} onChange={e => setValue(e.target.value)} />
            }
        },
        {
            key: "countedByLabel", title: "Counted By", flex: 2, Component: ({ value, setValue }) => {
                return <TextInput value={value ?? ''} />
            }
        },

    ], [store]);

    return <TableInput hasGroups minimal columns={cols} value={items} setValue={setItems} />;
}

function ReadonlyItemListField({ items }) {
    const cols = useMemo(() => [
        {
            key: "item", title: "Item", flex: 3, Component: ({ value, setMultiProp }) => {
                return <TextInput value={value?.name ?? ''} />
            }
        },
        {
            key: "counting", title: "Counting", flex: 1, Component: ({ value, setValue }) => {
                return <TextInput value={value} />
            }
        },
        {
            key: "newQty", title: "New Qty", flex: 1, Component: ({ useEntry }) => {
                const [itemId] = useEntry("itemId");

                const itemTotalCount = useMemo(() => {
                    if (!itemId) {
                        return undefined;
                    }

                    if (!items) {
                        return 0;
                    }

                    return items
                        .filter(item => item.itemId == itemId)
                        .map(item => big(item.counting))
                        .reduce((t, c) => t.add(c), big(0))
                        .toNumber();
                }, [itemId, items]);

                return <TextInput style={{ fontWeight: 'bold' }} value={itemTotalCount ?? ''} />
            }
        },
        {
            key: "memo", title: "Memo", flex: 3, group: "Adjustment Info", Component: ({ value, setValue }) => {
                return <TextInput value={value ?? ''} />
            }
        },
        {
            key: "countedByLabel", title: "Counted By", flex: 2, Component: ({ value, setValue }) => {
                return <TextInput value={value ?? ''} />
            }
        },

    ], [items]);

    return <TableInput minimal columns={cols} value={items} setValue={() => { }} readonly hideDummy />;
}


function PendingOptions({ item, onUpdate }) {
    const [loading, setLoading] = useState(false);

    const onFinalizeBtn = async () => {
        const confirmed = await UIUtil.confirmPrompt({
            message: "Are you sure you want to finalize this Stock Adjustment and modify the inventory?",
        })
        if (!confirmed) {
            return;
        }

        setLoading(true);
        try {
            const [success, response] = await Api.try((api, listener) => api.invAdjustmentFinalize(item.id, listener));
            if (!success) {
                return;
            }

            onUpdate(response)
        } finally {
            setLoading(false);
        }
    }

    if (item.status !== 'DRAFT') {
        return <></>;
    }

    return (<>
        <Button onClick={onFinalizeBtn} className="green-btn" loading={loading} size="sm" style={{ borderRadius: 25, marginLeft: '0.25rem' }} renderIcon={Checkmark16}>
            Finalize / Post
        </Button>
    </>)
}

const StatusIndicator = ({ status }) => {
    const { text, color } = (() => {
        switch (status) {
            case "DRAFT": return {
                text: "Draft",
                color: "#ffcc00"
            }

            case "POSTED": return {
                text: "Posted",
                color: "#0f62fe"
            }

            default: return {
                text: "Unknown",
                color: "#1c1c1c"
            }
        }
    })();

    return <>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', paddingLeft: '0.25rem' }}>
            <div style={{ width: 6, height: 6, background: color, borderRadius: 50, boxShadow: `0 0 50px 15px ${color}CC` }} />
            <span>{text}</span>
        </div>
    </>
};

const InfoCard = ({ data }) => {
    return <>
        <div style={{
            borderRadius: 7, border: '1px solid #00000020', boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.05) ', padding: '0.5rem',
            display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
            marginBottom: '2rem', marginTop: '-1rem'
        }}>
            <div>
                <h6>Status</h6>
                <StatusIndicator status={data.status} />
            </div>
            <div>
                <h6>Voucher No</h6>
                {Util.getVoucherNumber(data.id)}
            </div>
            <div>
                <h6>Created by</h6>
                {data.createdByName}
            </div>
            <div>
                <h6>Created on</h6>
                {Util.formatDate(data.createdOn, 'DD-MM')}
            </div>
            {data.status === 'POSTED' && <>
                <div>
                    <h6>Posted by</h6>
                    {data.postedByName}
                </div>
                <div>
                    <h6>Posted on</h6>
                    {Util.formatDate(data.postedOn, 'DD-MM')}
                </div>
            </>}
            <div>
                <h6>Document</h6>
                <Link style={{ cursor: 'pointer', lineHeight: 'unset' }} onClick={() => openPdf('InvAdjustmentDoc', data.id)}>Open PDF</Link>
            </div>
        </div>
    </>;
}

function ViewPage({ data }) {

    return (
        <div>
            <FormSection title="Adjustment Details">
                <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr', gap: '0.5rem' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '0.5rem' }}>
                        <div>
                            <h6>Voucher No</h6>
                            <p>{Util.getVoucherNumber(data.id)}</p>
                        </div>
                        <div>
                            <h6>Location</h6>
                            <p>{data.locationName}</p>
                        </div>
                    </div>
                    <div>
                        <h6>Memo</h6>
                        <p>{data.memo || '-'}</p>
                    </div>
                </div>
            </FormSection>
            {data.createdFlow && <>
                <FormSection title="Stock Creation">
                    <div>
                        <StockFlowItemPage
                            forceStockFlow={data.createdFlow}
                            renderItemsOnly
                        />
                    </div>
                </FormSection>
            </>}
            {data.terminatedFlow && <>
                <FormSection title="Stock Termination">
                    <div>
                        <StockFlowItemPage
                            forceStockFlow={data.terminatedFlow}
                            renderItemsOnly
                        />
                    </div>
                </FormSection>
            </>}

            <FormSection title="Items">
                <div style={{ marginTop: '1rem' }}>
                    <ReadonlyItemListField items={data.items} />
                </div>
            </FormSection>

            <div style={{ marginTop: '3rem', overflow: 'hidden', width: '100%', height: 400, borderRadius: 15, background: '#fafafa', border: '1px solid #00000020' }}>
                <AdvancedNotesView title="Notes/Attachments" objectId={data.id} objectType={OBJECT_TYPE_INV_ADJUSTMENT} />
            </div>
        </div>
    )
}

const notificationAudio = new Audio("/positive.wav");

function Form({ endpoint, form, data, save }) {
    form.useChangeListener('locationType', () => {
        form.setField("locationId", 0)
    })

    const [scanning, setScanning] = useState(false);

    useBarcodeScanner(scanning, async barcode => {
        let items = form.store.get("items") ?? []
        let item = items.find($ => ($?.item?.barcodes || []).includes(barcode))

        if (!item) {
            const [success, response] = await Api.try((api, listener) => api.searchStockReqItemsByBarcode(barcode, listener), true, true);
            if (!success || !response) {
                return;
            }

            item = response;
            items = [...items, {
                // REPEATED above in table input form
                id: Util.newTempId(),
                item,
                itemId: item?.itemId ?? 0,
                itemType: item?.itemType ?? 0,
            }];

            form.store.set("items", items);
        }

        if (item) {
            const update = items.map($ => $?.itemId === item.itemId ? ({
                ...$, counting: big($.counting).add(big(1)).toNumber()
            }) : $)
            form.store.set("items", update)

            try {
                notificationAudio.pause();
                notificationAudio.currentTime = 0;
                notificationAudio.play().catch(() => { })
            } catch (e) { }
        }
    })

    if (data?.status === 'POSTED') {
        return (
            <div style={{ paddingInline: '3rem' }}>
                {data && <InfoCard data={data} />}

                <ViewPage data={data} />
            </div>
        )
    }

    return (
        <div>
            <div style={{ paddingInline: '3rem' }}>
                {data && <InfoCard data={data} />}

                <FormSection title="Adjustment">
                    <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns: '1fr 1fr 1fr' }}>
                        <form.SwitchField title="Location Type" fieldKey="locationType" options={[{ id: OBJECT_TYPE_STORE, title: "Store" }, { id: OBJECT_TYPE_WAREHOUSE, title: "Warehouse" }]} />

                        <form.IfTargetGroup targetKey={"locationType"} checkTargetIf={type => type === OBJECT_TYPE_STORE}>
                            <form.ComboBoxField fieldKey="locationId" title="Store" options={endpoint.stores} />
                        </form.IfTargetGroup>
                        <form.IfTargetGroup targetKey={"locationType"} checkTargetIf={type => type === OBJECT_TYPE_WAREHOUSE}>
                            <form.ComboBoxField fieldKey="locationId" title="Warehouse" options={endpoint.warehouses} />
                        </form.IfTargetGroup>

                        <div />
                    </div>
                </FormSection>
            </div>

            <FormSection title={"Scanner"} titleStyle={{ paddingInline: '3rem' }}>
                {scanning ? (<>
                    <div style={{
                        background: '#1c1c1c', color: 'white', paddingBlock: '1rem',
                        paddingInline: '3rem',
                        // display: 'flex', gap: '1rem'
                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                            <Scan32 />
                            <h4 style={{ fontWeight: 'bold' }}>Scanner Active</h4>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                            <Button onClick={() => setScanning(false)} size="sm" kind="secondary" renderIcon={Close16} style={{ borderRadius: 5, marginTop: '1rem' }}>Stop Scanner</Button>
                            <Button className="green-button" onClick={() => {
                                setScanning(false)
                                save()
                            }} size="sm" kind="primary" renderIcon={Checkmark16} style={{ borderRadius: 5, marginTop: '1rem' }}>Stop and Save</Button>
                        </div>
                    </div>
                </>) : (<>
                    <Button onClick={() => setScanning(true)} renderIcon={Barcode16} style={{ borderRadius: 5, marginInline: '3rem' }}>Start Scanner</Button>
                </>)}
            </FormSection>
            <FormSection title={"Items"} titleStyle={{ paddingInline: '3rem' }}>
                <form.ExtField>
                    {ItemListField}
                </form.ExtField>
            </FormSection>

            <FormSection title={"Memo"} titleStyle={{ paddingInline: '3rem' }}>
                <form.TextAreaField fieldKey="memo" />
            </FormSection>

            {data && <div style={{ paddingInline: '3rem' }}>
                <FormSection title={"Notes/Docs"}>
                    <div style={{ marginTop: '0rem', overflow: 'hidden', width: '100%', height: 400, borderRadius: 15, background: '#fafafa' }}>
                        <AdvancedNotesView title="" objectId={data.id} objectType={OBJECT_TYPE_INV_ADJUSTMENT} />
                    </div>
                </FormSection>
            </div>}
        </div>
    )
}

const STATUSES = [
    'Draft',
    'Posted',
]

function Filter({ endpoint, state }) {
    return (<>
        <DateRange2 state={state} property="postedOn" />

        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 1 }}>
            <MultiSelectListMode isMulti={false} placeholder="Status" fieldKey="invadjuststatus" options={STATUSES} state={state} property="status" />
        </div>

        <div style={{ flex: 1 }}>
            <MultiSelectListMode isMulti={false} placeholder="Location" fieldKey="invajudstlocation" options={endpoint.locations.map(c => c.value)} state={state} property="location" />
        </div>

        <div className="list-mode-divider-quick-filter-bar" />

        <div style={{ flex: 1 }}>
            <TextBox state={state} label="Voucher No" property="voucherNo" fullWidth />
        </div>
    </>)
}
